







































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

@Component({
  components: {
  },
})
export default class TermsAndConditions extends Mixins(View) {
}
